/*!
=========================================================
*  Argon Design System  - v 1.2.0
=========================================================

* Product Page:  https://www.creative-tim.com/product/argon-design-system
* Copyright  2020  Creative Tim (http://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

// Bootstrap Functions
@import "bootstrap/functions";

// Theme Variables
@import "argon-design-system/variables";

// Bootstrap Core
@import "bootstrap/bootstrap";

// Theme Core
@import "argon-design-system/theme";
